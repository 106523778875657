<template lang="html">
  <section v-in-viewport v-if="posts.timeline" class="Timeline ScrollTarget">
    <div v-bind:class="loading">
      <h2>What have I been up to over the years?</h2>
      <div class="Timeline__event-wrapper">
        <div 
          v-for="post in posts.timeline" 
          :key="post.content"
          class="Timeline__event"
        >
            <h3>{{post.time}}</h3>
            <div v-html="post.content"></div>
        </div>
      </div>
      <ArrowDown/>
    </div>
  </section>
</template>

<script>
  import ArrowDown from '@/components/ArrowDown.vue'
  export default {
      props: ['posts','loading'],
      components: {
        ArrowDown
      }
  }
</script>

<style lang="less" scoped>

  @import '../styles/variables.less';

  @timeline_width: 90%;

  .Timeline{
    &__event{
      width: @timeline_width/2;
      background: @color_light_gray;
      padding: 20px;
      clear: both;
      position: relative;
      color: #50677c;
      opacity: 0;
      z-index: 10;

      @media screen and (max-width: @breakpoint-sm) {
          margin: 0 0 40px;
          width: auto;
      }

      h3{
        position: absolute;
        right: -(@timeline_width/8);
        font-size: 10px;
        height: 30px;
        width: 30px;
        background: @color_blue_gray;
        line-height: 30px;
        text-align: center;
        border-radius: 50%;
        top: 50%;
        margin: -15px -17px;
        z-index: 10;
        color: white;

        @media screen and (max-width: @breakpoint-sm) {
          right: auto;
          left: -34px;
          margin: -12px 3px;
          font-size: 9px;
          height: 24px;
          width: 24px;
          line-height: 26px;
        }
      }

      &:before{
        content: "";
        position: absolute;
        background: @color_light_gray;
        top: 50%;
        width: @timeline_width/8;
        height: 1px;
        right: -(@timeline_width/8);

        @media screen and (max-width: @breakpoint-sm) { 
            right: auto;
            left: -20px;
            width: 46px;
        }

      }

      &:nth-child(even){
        float: right;

        h3,
        &:before{
          right: auto;
          left: -(@timeline_width/8);

          @media screen and (max-width: @breakpoint-sm) {
            left: -30px;
          }

        }
        h3{
          margin-left: -12px;
          @media screen and (max-width: @breakpoint-sm) {
            margin: -12px 3px;
            left: -34px;
          }
        }

        @media screen and (max-width: @breakpoint-sm) {
            float: none;
            margin-left: 0;
        }

      }
      
      &-wrapper{
        position: relative;
        overflow: hidden;
        margin: 40px 0;
        padding: 40px 0;

        @media screen and (max-width: @breakpoint-sm) {
          padding: 0;
          overflow: visible;
        }

        &:after{
          content: "";
          position: absolute;
          height: 100%;
          width: 4px;
          left: 50%;
          top: 0;
          background: @color_light_gray;
          z-index: 1;
          opacity: 0;

          @media screen and (max-width: @breakpoint-sm) {
            left: -21px;
          }

        }
      }
    }
  }


  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .in-viewport{
    
    .Timeline__event-wrapper:after{
      animation: fadeIn 2s ;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }

    .Timeline__event{
      animation: fadeIn 2s ;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;

      &:nth-child(1){
        animation-delay: .5s;
      }

      &:nth-child(2){
        animation-delay: 1s;
      }

      &:nth-child(3){
        animation-delay: 1.5s;
      }

      &:nth-child(4){
        animation-delay: 2s;
      }

      &:nth-child(5){
        animation-delay: 2.5s;
      }

      &:nth-child(6){
        animation-delay: 3s;
      }

      &:nth-child(7){
        animation-delay: 3.5s;
      }

      &:nth-child(8){
        animation-delay: 4s;
      }

    }
  }
</style>


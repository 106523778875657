import Vue from 'vue'
import App from './App.vue'
import router from './router'
import inViewportDirective from 'vue-in-viewport-directive'

Vue.directive('in-viewport', inViewportDirective)

Vue.config.productionTip = false

new Vue({
  router,
  inViewportDirective,
  render: h => h(App)
}).$mount('#app')


import axios from 'axios'

export default {

    getAjaxPosts() {
        const endpoint = 'https://wp.workin.se/wp-json/acf/v3/pages'
        return axios.get(endpoint, {
            params: {
                id: 2
            }
        })
    }    
};

<template lang="html">
  <section v-in-viewport class="Clients Section ScrollTarget">
    <div v-bind:class="loading">
      <h2>Who do I work with?</h2>
      <div class="client_wrapper">
        <div class="client" v-for="content in posts.clients" :key="content.client">
          <div>
            <img :src="content.client" alt="">
          </div>
          <a :href="content.client_link">
            {{content.client_name}}
          </a>
        </div>
      </div>
      <ArrowDown/>
    </div>
  </section>
</template>

<script>
  import ArrowDown from '@/components/ArrowDown.vue'
  export default {
      props: ['posts','loading'],
      components: {
        ArrowDown
      }
  }
</script>

<style lang="less" scoped>
  @import '../styles/variables.less';
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .Clients{
    background: @color_dark_blue_gray;
    color: white;
    text-align: center;
    position: relative;

    h2{
      color: white;
    }
    
    img{
      max-width: 100%;
    }

    a{
      color: white;
      text-decoration: none;
      font-size: 13px;
      text-transform: uppercase;
      letter-spacing: 2px;
    }

    .client{
      display: inline-block;
      vertical-align: middle;
      opacity: 0;

      @media screen and (max-width: @breakpoint-sm) {
        margin-bottom: 40px;
      }

      > div{
        height: 160px;
        width: 160px;
        border-radius: 50%;
        background: white;
        margin: 10px 20px;
        padding: 15px;
        text-align: center;
      }

      img{
          max-width: 100%;
          margin-top: 25px;
      }  

      &:first-child{
        img{
          margin-top: 10px;
          width: 114px;
        }
      }

      &:nth-child(2){
        img{
          margin-top: 30px;
        }
      }

      &:nth-child(3){
        img{
          width: 90px;
          margin-top: 21px;
        }
      }

      &:nth-child(4){
        img{
          margin-top: 12px;
        }
      }
    
    }

    &.in-viewport{
    
      .client{
        animation: fadeIn 2s ;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;

        &:nth-child(1){
          animation-delay: .5s;
        }

        &:nth-child(2){
          animation-delay: 1s;
        }

        &:nth-child(3){
          animation-delay: 1.5s;
        }

        &:nth-child(4){
          animation-delay: 2s;
        }

      }
    }
  }
</style>


<template lang="html">
  <section v-in-viewport class="Section Play ScrollTarget">
    <div>
        <h2>A perfect match?</h2>
        <div class="play_wrapper" v-bind:class="loading">
          <div class="play" v-for="content in posts.play" :key="content.content">
            <div v-html="content.content"></div>
          </div>
        </div>
        <div class="contact">
            <img src="http://backend.workin.se/wp-content/uploads/2019/01/hannes-300x300.png" alt="Hannes Cederqvist">
            <h3>Get in touch!</h3>
            <p>Hannes Cederqvist</p>
            <p>hannes@workin.se</p>
            <p>+46(0)70798 05 82</p>
            <p><a href="https://www.linkedin.com/in/hannescederquist/">More details on my LinkedIn-profile</a></p>
        </div>
    </div>
  </section>
</template>

<script>
  export default {
    props: ['posts','loading']
  }
</script>

<style lang="less" scoped>

  @import '../styles/variables.less';

  .play_wrapper{
    overflow: hidden;

    .play{
      float: left;
      width: 50%;
      padding: 10px;

      @media screen and (max-width: @breakpoint-sm) {
        float: none;
        width: 100%;
        text-align: left;
      }

      &:first-child{
        padding-left: 0;
        @media screen and (max-width: @breakpoint-sm) {
          padding-bottom: 0;
        }
      }

      &:last-child{
        padding-right: 0;

        @media screen and (max-width: @breakpoint-sm) {
          padding: 0;
        }

      }

    }

  }

  .contact{
    text-align: center;
    margin-top: @base_margin;
    img{
      width: 200px;
      margin-bottom: 20px;
    }
  }

</style>

<template lang="html">
  <section v-in-viewport class="Section Header ScrollTarget">
      <header>
          <a id="logo" href="/"><img alt="Vue logo" src="../assets/logo.svg"></a>
          <small>Hannes Cederqvist</small>
          <ArrowDown/>
      </header>  
  </section>
</template>

<script>
  import ArrowDown from '@/components/ArrowDown.vue'

  export default {
    components:{
      ArrowDown
    }
  }
</script>

<style lang="less" scoped>

  @import '../styles/variables.less';

  #logo{
    display: block;
    margin-top: -60px;
    text-align: center;

    img{
      width: 240px;
      max-width: 100%;
    }
  }

  small{
    display: inline-block;
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 2px;
    position: relative;
    padding: 0 60px;
    margin: 10px 0 0;

    &:before,
    &:after{
      content: "";
      position: absolute;
      height:  0.1em;
      width: 30px;
      left: 20px;
      background: rgba(44,62,80, .4);
      top: 6px;
    }
    &:after{
      left: auto;
      right: 20px;
    }
  }
</style>


<template>
    <div id="app">
        <Header/>
        <app-intro :posts="posts" :loading="loadingObject"></app-intro>
        <app-timeline :posts="posts" :loading="loadingObject"></app-timeline>
        <app-clients :posts="posts" :loading="loadingObject"></app-clients>
        <app-play :posts="posts" :loading="loadingObject"></app-play>
        <router-view/>
    </div>
</template>

<script>
    import Header from '@/components/Header.vue'
    import Intro from '@/components/Intro.vue'
    import Timeline from '@/components/Timeline.vue'
    import Clients from '@/components/Clients.vue'
    import Play from '@/components/Play.vue'
    import Posts from '@/services/PostsAjax.js'

    export default {
        
        data(){
            return {
                posts: [],
                loadingObject: {
                    vueLoading: true
                }
            }
        },
        components: {
            Header,
            'app-intro': Intro,
            'app-timeline': Timeline,
            'app-clients': Clients,
            'app-play': Play
        },
        methods: {

            getPosts:function() {
                this.loadingObject.vueLoading = true
                Posts.getAjaxPosts().then(posts => {
                    this.posts = posts.data[0].acf
                })
                .catch( error => {
                    // eslint-disable-next-line
                    console.log(error)
                })
                .finally( () => {
                    this.loadingObject.vueLoading = false
                }) 
            }
        },
        created() {
            this.getPosts()
        }
    }
</script>

<style lang="less">

    @import 'styles/variables.less';
    @import 'styles/loader.less';

    *{
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }

    h3{
        margin-bottom: 30px;
    }

    li{
        margin-bottom: 20px;
        list-style: none;
        padding-left: 20px;
        position: relative;

        &:before{
        position: absolute;
        left: 0;
        top: 2px;
        font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
        content: "\2713";
        font-weight: bold;
        }
    }

    a{
        color: @color_dark_blue_gray;
    }

    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: @color_dark_blue_gray;
        margin: auto;
        padding-bottom: 60px;
    }


    .ScrollTarget{
        min-height: 100vh;
        position: relative;
        padding: 60px 0;
        width: 100%;
        
        > div{
            width: 1080px;
            max-width: 100%;
            margin: auto;
            padding-left: 40px;
            padding-right: 40px;
        }
    }

    .Section{
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;

        &.in-viewport{
            opacity: 1;
            transition: opacity .5s ease-in-out;
        }
    }


    h2{
        text-transform: uppercase;
        letter-spacing: 3px;
        color: @color_dark_blue_gray;
        margin-bottom: @base_margin;
        text-align: center;
    }

</style>

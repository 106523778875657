<template lang="html">
    <section v-in-viewport class="Section Intro ScrollTarget">
        <div v-bind:class="loading">
            <h2>Who am I?</h2>
            <div class="intro_wrapper">
                <div v-html="posts.intro"></div>
            </div>
            <ArrowDown/>
        </div>
    </section>
</template>

<script>
    import ArrowDown from '@/components/ArrowDown.vue'

    export default {
        props: ['posts','loading'],
        components: {
            ArrowDown,

        }
    }
</script>

<style lang="less" scoped>
    @import '../styles/variables.less';

    section{
        background: @color_light_gray;
    }

    #logo{
        display: block;
        margin-top: -80px;

        img{
            width: 240px;
            max-width: 100%;
        }
    }

    section .intro_wrapper > div {
        columns: 2;
        text-align: left;
        @media screen and (max-width: @breakpoint-sm) {
            columns: 1;
        }
    }

    small{
        display: inline-block;
        text-transform: uppercase;
        font-size: 10px;
        letter-spacing: 2px;
        position: relative;
        padding: 0 60px;
        margin: 10px 0 40px;

        &:before,
        &:after{
        content: "";
            position: absolute;
            height:  0.1em;
            width: 30px;
            left: 20px;
            background: rgba(44,62,80, .4);
            top: 6px;
        }
        &:after{
            left: auto;
            right: 20px;
        }
    }
    .Intro{
        padding: 100px 0
    }
</style>


<template lang="html">
    <div>
        <transition name="bounce">
            <div class="btn-wrapper">
                <button
                    id="arrow-down"
                    v-on:click="scrollDown"
                    v-bind:class="{ active: isActive}"
                    class="pulse-enter-active"
                >&nbsp;</button>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    data() {
      return {
        isActive: false,
      }
    },
    methods: {
        scrollDown: function(event){
            this.isActive = !this.isActive
            const nextSection = event.target.closest('.ScrollTarget').nextSibling
            nextSection.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
        }
    }
}
</script>

<style lang="less" scoped>

    @import '../styles/variables.less';
    @import '../styles/animations.less';

    .btn-wrapper{
        text-align: center;
    }

    button{
        margin-top: @base_margin;
        border: solid @color_dark_blue_gray;
        border-width: 0 5px 5px 0;
        padding: 5px;
        width: 20px;
        height: 20px;
        outline: none;
        cursor: pointer;
        transform: rotate(45deg);
        z-index: 1000;
        background: transparent;

        &:active{
            outline: none;
        }
    }

    .Clients{
        button{
            border-color: @color_light_gray;
        }
    }

    .pulse-enter-active {
        animation: pulsate 1.3s;
        animation-iteration-count: infinite;
    }
    
    @keyframes pulsate {
        0% {
            transform: rotate(45deg) scale(1);
        }
        50% {
            transform: rotate(45deg) scale(1.2);
        }
        100% {
            transform: rotate(45deg) scale(1);
        }
    }

</style>

